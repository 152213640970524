import { createIndication } from '../../services/indications';
import { CreateIndicationParams, DatacoreResponse } from '../../types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import useInvalidation from '../useInvalidation';

const KEY = 'INDICATIONS';

export function useCreateIndication(
  options?: UseMutationOptions<
    AxiosResponse<DatacoreResponse>,
    AxiosError<{ message: string }>,
    CreateIndicationParams
  >,
) {
  const invalidate = useInvalidation(KEY);

  return useMutation<
    AxiosResponse<DatacoreResponse>,
    AxiosError<{ message: string }>,
    CreateIndicationParams
  >({
    mutationFn: (data: CreateIndicationParams) => createIndication(data),
    ...{
      onSuccess: () => {
        return invalidate();
      },
      ...options,
    },
  });
}
