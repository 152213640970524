import { styled } from '@formbio/ui/components';
import { ReactNode } from 'react';

const StyledCard = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  height: '100%',
  padding: theme.spacing(2),
  width: '600px',
  '.rows': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

/**
 * A simple card component with 2 centered row.
 * Used as an empty state for projects and orgs
 * with an icon on topRow and a text on bottomRow
 */
export default function EmptyStateCard({
  topRow,
  bottomRow,
}: {
  topRow: ReactNode;
  bottomRow: ReactNode;
}) {
  return (
    <StyledCard>
      <div className='rows'>
        {topRow}
        {bottomRow}
      </div>
    </StyledCard>
  );
}
