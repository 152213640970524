import {
  CreateGeneParams,
  DATACORE_GENE_TYPE,
  DatacoreResponse,
} from '../../types';
import { api } from '../../utils/api';

export function createGene({
  orgId,
  pid,
  geneName,
  indicationId,
}: CreateGeneParams) {
  return api.post<DatacoreResponse>(
    `/orgs/${orgId}/${pid}/datacore/entities/${DATACORE_GENE_TYPE}`,
    {
      data: {
        type: 'entities',
        attributes: {
          name: geneName,
          typeName: DATACORE_GENE_TYPE,
        },
      },
      relationships: {
        parentEntity: {
          data: {
            type: 'entities',
            id: indicationId,
          },
        },
      },
    },
  );
}
